export const API_URLS = {
  AUTH: {
    LOGIN_USER: "/login",
    REGISTER_USER: "/register",
    SEND_OTP_TO_MOBILE: "/sendOtpToMobile",
    MOBILE_VERIFICATION: "/mobileVerification",
    GET_EXPOSURE_AMOUNT: "/bet/exposure/amount",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    PAGE_VISIT: "/page/visit",
  },
  USER: {
    ACCOUNT_STATEMENT: "/getAccountstatement",
    CREATE_WITHDRAW_REQUEST: "/withdrawRequest/create",
    CREATE_DEPOSIT: "/deposit/create",
    CANCEL_WITHDRAW_REQUEST: "/withdrawRequest/cancel",
    GET_WITHDRAW_LIST: "/withdrawRequest/get",
    FASTPAYME_DEPOSIT: "/deposit/fastpayme",
    GET_ALL_GATEWAYS: "/gateways",
    PLAYER_BANK_DETAILS: "/bank",
  },
  APP: {
    APP_DETAILS: "/getAppDetails",
    BANK_DETAILS: "/getBankAccountDetails",
    GET_BANNERS: "/getBanners",
  },
  SPORTS: {
    PLACE_BET: "/bet/match/odds",
    GET_BET_MATCHES: "/bet/matches",
    GET_USER_BETS: "/bets",
    PLACE_FANCY_BET: "/bet/fancy/odds",
    GET_LIVE_TV_URL:
      "https://ss247.life/api/66d33b52756913b5cc220b1ae2761bb4fc874bfc/streaminfoCR.php?Type=CRICKET",
    PLACE_BOOKMAKER_BET: "/bet/bookmaker/odds",
    GET_MATCH_LIABILITY: "/sports/match/settings",
  },
  BONUS: {
    CLAIMS: "/bonus/claims",
    STATS: "/bonus/claims/stats",
    PARTS: "/bonus/claims/parts",
    CANCEL: "/bonus/claims/cancel",
  },
  REFERRAL: {
    GENERATE: "/ref/generate",
    STATS: "/ref/stats",
    CHILDREN: "/ref/children",
    SOURCES: "/ref/sources",
  },
};
